import http from "./httpServices";
import { apiUrl } from "../config.json";
import auth from "./authService";

const apiEndpoint = `${apiUrl}/profile`;

http.setJwt(auth.getJwt());

export async function updateProfile(data, section, update_user_id) {
  console.log("byId");
  console.log(data, section, update_user_id);
  const body = {
    section: section,
    content: data,
  };
  await http.put(apiEndpoint + "/byid/" + update_user_id, body);
}

export async function fetchProfile() {
  return await http.get(apiEndpoint);
}

export async function mobileVerificationRequest(mobile) {
  return await http.post(apiEndpoint + "/mobile/verification/request", {
    mobile,
  });
}

export async function mobileVerificationValidate(mobile, validationkey) {
  return await http.post(apiEndpoint + "/mobile/verification/validate", {
    mobile,
    validationkey,
  });
}

export async function requestReview() {
  return await http.get(apiEndpoint + "/request/review");
}

export async function cancelRequestReview() {
  return await http.get(apiEndpoint + "/request/cancel-review");
}

export default {
  updateProfile,
  fetchProfile,
  mobileVerificationRequest,
  mobileVerificationValidate,
  requestReview,
  cancelRequestReview,
};
