import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import uploadservice from "../../services/uploadService";

function UploadFotos(props) {
  const [processingImg, setProcessingImage] = useState(false);
  const [nowlimit, setLimit] = useState(props.limit);
  useEffect(() => {
    setLimit(props.limit);
  }, [props.limit]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Test Limit
      if (acceptedFiles.length > nowlimit) {
        return toast.warning(
          `Your files couldn't be upload. You have only ${nowlimit} photos left.`
        );
      }
      // Test Type
      const validateFilesType = _.findIndex(acceptedFiles, function (o) {
        return (
          o.type !== "image/jpeg" &&
          o.type !== "image/jpg" &&
          o.type !== "image/png"
        );
      });
      if (validateFilesType !== -1) {
        return toast.warning(
          `Only PNG, JPEG, and JPG files are allowed to upload.`
        );
      }
      // Test file size
      const validateFilesSize = _.findIndex(acceptedFiles, function (o) {
        return o.size > 10000000;
      });
      if (validateFilesSize !== -1) {
        return toast.warning(`The upload limit is 10MB per file.`);
      }

      try {
        setProcessingImage(true);
        const updatedData = await uploadservice.upload(
          acceptedFiles,
          props.profileUserId
        );
        toast.success("Your photos has been uploaded sucessfully! 📸");
        props.handleDataUpdate(updatedData["data"], "images_gallery");
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.warning(ex.response.data);
        } else {
          toast.error(ex.toString());
        }
      } finally {
        setProcessingImage(false);
      }
    },
    [nowlimit]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <>
      <ToastContainer />
      <div className={props.class || "mb-12"}>
        <div
          {...getRootProps()}
          className={processingImg === false ? `dragndroparea` : `d-none`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <p class="dndmessage">
                Drag 'n' drop some files here, or click to select files
                <br />
                <span className="uploadlabel">
                  File Types: JPG, JPEG, PNG | File size limit: 10MB per photo
                </span>
              </p>
              <p class="dndmobile">
                Click to select files
                <br />
                <span className="uploadlabel">
                  File Types: JPG, JPEG, PNG | File zize limit: 10MB per photo
                </span>
              </p>
            </>
          )}
        </div>
        <div className={processingImg === false ? `d-none` : `dragndroparea`}>
          <img
            src="/assets/images/processing.gif"
            alt="processing"
            height="92"
          />
        </div>
      </div>
    </>
  );
}

export default UploadFotos;
