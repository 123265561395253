import React from "react";

const PhoneDetails = ({ name, error, item, onChange, value, ...rest }) => {
  return (
    <>
      <div className={`form-group col-md-6`}>
        <label htmlFor="email">Phone {item}</label>
        <input
          {...rest}
          className="form-control"
          name={`phone${item}`}
          id={`phone${item}`}
          placeholder=""
          onChange={onChange}
          value={value}
          item={item}
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </>
  );
};

export default PhoneDetails;
