import React, { useState, useEffect } from "react";
import { applyMiddleware } from "redux";
import AppointmentCard from "./appointmentCard";
import format from "date-format";
import { fetchAgenda, setStatus, setNotes } from "../../services/booking";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const ManageBooking = () => {
  const weekDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function getFirstDayMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  function getLastDayMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  function getMondayOfWeek(d) {
    var day = d.getDay();
    return new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate() + (day == 0 ? -6 : 1) - day
    );
  }
  function getSundayOfWeek(d) {
    var day = d.getDay();
    return new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate() + (day == 0 ? 0 : 7) - day
    );
  }

  function labelDate(date) {
    const dt = new Date(
      date.toString().substring(0, 4),
      date.toString().substring(4, 6) - 1,
      date.toString().substring(6, 8)
    );

    return weekDay[dt.getDay()] + ", " + format("dd/MM/yyyy", dt);
  }

  //fetchAgenda,setStatus, setNotes
  const [periodType, setPeriodType] = useState("day"); // day, week, month
  const [period, setPeriod] = useState([new Date()]);
  const [periodLabel, setPeriodLabel] = useState();
  const [listOfAppointments, setListOfAppointments] = useState();
  const [agendaData, setAgendaData] = useState([]);
  const [filteredAgenda, setFilteredAgenda] = useState([]);

  const [showPending, setShowPending] = useState(true);
  const [showConfirmed, setShowConfirmed] = useState(true);
  const [showRecused, setShowRecused] = useState(false);
  const [showCanceled, setShowCanceled] = useState(false);

  const handlePeriodLabel = (dt, type) => {
    let label = "";
    let today = new Date();
    if (type === "day") {
      if (format("dd/MM/yyyy", dt[0]) === format("dd/MM/yyyy", today)) {
        label = "Today, ";
      }
      label = label + weekDay[dt[0].getDay()] + " ";
      return setPeriodLabel(label + format("dd/MM/yyyy", dt[0]));
    }

    if (type === "week") {
      if (dt[0] >= dt && dt[1] <= dt) {
        label = "This week, ";
      }
      return setPeriodLabel(
        label +
          format("dd/MM/yyyy", dt[0]) +
          " - " +
          format("dd/MM/yyyy", dt[1])
      );
    }

    if (type === "month") {
      return setPeriodLabel(
        monthNames[parseInt(format("MM", dt[0])) - 1] +
          " / " +
          format("yyyy", dt[0])
      );
    }
  };

  const changePeriod = (type) => {
    setPeriodType(type);
    const dt = new Date();
    switch (type) {
      case "day":
        setPeriod([dt]);
        handlePeriodLabel([dt], type);
        break;
      case "week":
        setPeriod([getMondayOfWeek(dt), getSundayOfWeek(dt)]);
        handlePeriodLabel([getMondayOfWeek(dt), getSundayOfWeek(dt)], type);
        break;
      case "month":
        setPeriod([getFirstDayMonth(dt), getLastDayMonth(dt)]);
        handlePeriodLabel([getFirstDayMonth(dt), getLastDayMonth(dt)], type);
        break;

      default:
        break;
    }
  };

  const handleArrows = (side) => {
    // side => 1 || -1
    if (periodType === "day") {
      let newDt = new Date(period[0]);
      newDt.setDate(period[0].getDate() + side);
      setPeriod([newDt]);
      handlePeriodLabel([newDt], "day");
      return;
    }

    if (periodType === "week") {
      let x = 7 * side;
      let newDt = new Date(period[0]);
      newDt.setDate(period[0].getDate() + x);
      const newPeriod = [getMondayOfWeek(newDt), getSundayOfWeek(newDt)];
      setPeriod(newPeriod);
      handlePeriodLabel(newPeriod, "week");
      return;
    }

    if (periodType === "month") {
      let month = parseInt(format("MM", period[0])) + side;
      let year = format("yyyy", period[0]);
      if (month === 0) {
        month = 12;
        year = format("yyyy", period[0]) - 1;
      }
      const newDt = new Date(year, month - 1, 1);

      const newPeriod = [newDt, getLastDayMonth(newDt)];

      setPeriod(newPeriod);
      handlePeriodLabel(newPeriod, "month");
      return;
    }
  };

  const handleToday = () => {
    setPeriod([new Date()]);
    handlePeriodLabel([new Date()], "day");
  };

  useEffect(() => {
    handlePeriodLabel([new Date()], "day");
  }, []);

  useEffect(() => {
    getAppointments();
  }, [period, showPending, showConfirmed, showRecused, showCanceled]);

  useEffect(() => {
    showFilteredAgenda();
  }, [agendaData]);

  const getAppointments = async () => {
    try {
      let dt = period;
      if (dt.length === 1) {
        dt.push(dt[0]);
      }
      const resp = await fetchAgenda([
        parseInt(format("yyyyMMdd", dt[0])),
        parseInt(format("yyyyMMdd", dt[1])),
      ]);
      setFilteredAgenda([]);
      return setAgendaData(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditNote = async (id, note) => {
    try {
      await setNotes(id, note);
      toast.success("Your note has been saved!");
      getAppointments();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  const handleStatus = async (id, status) => {
    try {
      await setStatus(id, status);
      toast.success("The status has changed!");
      getAppointments();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  const showFilteredAgenda = () => {
    if (!agendaData || agendaData.length === 0) {
      return "No results for the selected period ans status selection!";
    }
    setFilteredAgenda();

    let filtered = [];

    for (let i = 0; i < agendaData.length; i++) {
      let element = agendaData[i];

      let filteredAppoint = [];
      for (let j = 0; j < element.appointments.length; j++) {
        let appoint = element.appointments[j];

        if (showPending === true && appoint.status === "Pending") {
          filteredAppoint.push(appoint);
        }
        if (showConfirmed === true && appoint.status === "Confirmed") {
          filteredAppoint.push(appoint);
        }
        if (showRecused === true && appoint.status === "Recused") {
          filteredAppoint.push(appoint);
        }
        if (showCanceled === true && appoint.status === "Canceled") {
          filteredAppoint.push(appoint);
        }
      }
      if (filteredAppoint.length > 0) {
        element.appointments = filteredAppoint;
        filtered.push(element);
      }
    }

    setFilteredAgenda(filtered);
  };

  return (
    <>
      <ToastContainer />
      <div class="content-page">
        <div class="content">
          <div class="page-content-wrapper">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-6">
                  <div class="main-title pb-0">
                    <h4 class="page-title">Booking Management</h4>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="main-title mobile-mt-0 ">
                    <div class="float-right">
                      <div class="btn-toolbar mb-4 " role="toolbar">
                        <div class="btn-group mr-2">
                          <button
                            type="button"
                            class={`btn btn-primary ${
                              showPending ? "bg-dark-green" : ""
                            }`}
                            onClick={() => setShowPending(!showPending)}
                          >
                            <i class="fa fa-inbox"></i> Pending
                          </button>
                          <button
                            type="button"
                            class={`btn btn-primary ${
                              showConfirmed ? "bg-dark-green" : ""
                            }`}
                            onClick={() => setShowConfirmed(!showConfirmed)}
                          >
                            <i class="fas fa-calendar-check"></i> Confirmed
                          </button>
                          <button
                            type="button"
                            class={`btn btn-primary ${
                              showRecused ? "bg-dark-green" : ""
                            }`}
                            onClick={() => setShowRecused(!showRecused)}
                          >
                            <i class="far fa-trash-alt"></i> Recused
                          </button>
                          <button
                            type="button"
                            class={`btn btn-primary ${
                              showCanceled ? "bg-dark-green" : ""
                            }`}
                            onClick={() => setShowCanceled(!showCanceled)}
                          >
                            <i class="far fa-calendar-times"></i> Canceled
                          </button>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="card m-b-30">
                    <div class="card-body">
                      <div class="row">
                        <div className="col-lg-8 p-2">
                          <button
                            type="button"
                            class="btn btn-primary ml-1"
                            onClick={() => handleArrows(-1)}
                          >
                            <i className="fas fa-angle-left"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary ml-1"
                            onClick={() => handleArrows(1)}
                          >
                            <i className="fas fa-angle-right"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary  ml-2"
                            onClick={handleToday}
                          >
                            Today
                          </button>

                          <button type="button" class="btn btn-light ml-2">
                            {periodLabel}
                          </button>
                        </div>
                        <div class="col-lg-4 p-2 text-lg-right">
                          <div class="btn-group mr-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              disabled={periodType === "day" ? true : false}
                              onClick={() => changePeriod("day")}
                            >
                              Day
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              disabled={periodType === "week" ? true : false}
                              onClick={() => changePeriod("week")}
                            >
                              Week
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              disabled={periodType === "month" ? true : false}
                              onClick={() => changePeriod("month")}
                            >
                              Month
                            </button>
                          </div>{" "}
                          <div class="btn-toolbar" role="toolbar"></div>
                        </div>
                      </div>

                      <hr className="mb-4" />

                      {filteredAgenda && filteredAgenda.length > 0
                        ? filteredAgenda.map((item, index) => (
                            <>
                              <h5>{labelDate(item.date)}</h5>
                              {item.appointments.map((appointment, index) => (
                                <AppointmentCard
                                  data={appointment}
                                  key={index}
                                  handleEditNote={handleEditNote}
                                  handleStatus={handleStatus}
                                />
                              ))}
                            </>
                          ))
                        : "No appointments found!"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageBooking;
