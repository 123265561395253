import React from "react";

const SingleCitySelect = ({
  label,
  name,
  className,
  values,
  selected,
  onChange,
  ...rest
}) => {
  function option(value) {
    let itens = [<option value=" ">-- {value["state"]} --</option>];
    for (let i = 0; i < value["cities"].length; i++) {
      itens.push(
        <option value={value["cities"][i]}>{value["cities"][i]}</option>
      );
    }
    return itens.map((item) => item);
  }

  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={label}>{label}</label>

      <select
        className="select2 form-control mb-3 custom-select"
        style={{ width: 100 + "%", height: 36 + "px" }}
        onChange={onChange}
        name={name}
        value={selected}
      >
        <option>Select</option>
        {values.map((item) => {
          return option(item);
        })}
      </select>
    </div>
  );
};

export default SingleCitySelect;
