import React, { useState, useEffect } from "react";

function ApprovalModal(props) {
  const defaultMessage = {
    reject: "",
    approve: "",
  };
  const [description, setDescription] = useState(defaultMessage[props.status]);

  const submitForm = (e) => {
    e.preventDefault();
    props.handleApprovalStatus(props.status, description);
  };
  return (
    <div class="modal-body">
      <form onSubmit={submitForm}>
        <div class="row">
          <div class="form-group col-12">
            <label for="textarea">Comments:</label>
            <textarea
              name="description"
              class="form-control"
              maxlength="300"
              rows="5"
              onChange={(e) => setDescription(e.target.value)}
            >
              {description}
            </textarea>
          </div>
        </div>
        {props.status === "reject" ? (
          <button
            class={`btn btn-danger w-100 ${
              description.length >= 15 ? "" : "d-none"
            }`}
          >
            Reject this request
          </button>
        ) : (
          ""
        )}
        {props.status === "approve" ? (
          <button
            class={`btn btn-success w-100 ${
              description.length >= 15 ? "" : "d-none"
            }`}
          >
            Approve and publish this request
          </button>
        ) : (
          ""
        )}
      </form>
    </div>
  );
}

export default ApprovalModal;
