import http from "./httpServices";
import { apiUrl } from "../config.json";

const apiEndpoint = `${apiUrl}/users`;
const tokenKey = "token";

export function register(user) {
  return http.post(apiEndpoint, {
    name: user.name,
    email: user.email,
    password: user.password,
    mobile: user.mobile,
    website: user.website,
  });
}

export function forgot(data) {
  return http.post(apiEndpoint + "/passrecovery", data);
}

export async function resetPassword(password, recovery_id) {
  const { data: jwt } = await http.post(apiEndpoint + "/resetpassword", {
    password,
    recovery_id,
  });
  localStorage.setItem(tokenKey, jwt);
}

export async function activeaccount(id) {
  await http.post(apiEndpoint + "/activeaccount", {
    active_id: id,
  });
  return;
}

export async function changePassword(data) {
  await http.put(apiEndpoint + "/update/password", data);
}

export async function changeBasicDetails(data) {
  const { data: jwt } = await http.put(apiEndpoint + "/update/details", data);
  localStorage.setItem(tokenKey, jwt);
}

export default {
  register,
  forgot,
  resetPassword,
  activeaccount,
  changePassword,
  changeBasicDetails,
};
