import React, { useState, useEffect } from "react";
import packages from "../services/packagesServices";
import PackageCard from "./package/packageCard";
import { ToastContainer, toast } from "react-toastify";
import { tokenKey } from "../config.json";

import "react-toastify/dist/ReactToastify.css";

const Packages = () => {
  const [data, setData] = useState();
  const fetchData = async () => {
    try {
      let response = await packages.fetchPackages();
      setData(response.data);
    } catch (ex) {
      window.location = "/";
    }
  };

  const handleSelect = async (package_id) => {
    try {
      let user = await packages.setPackages(package_id);

      localStorage.setItem(tokenKey, user.data);
      window.location = "/profile";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="content-page">
        <div className="content">
          <div className="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-title-box">
                    <h4 className="page-title">Select your package</h4>
                  </div>
                </div>
              </div>

              <div class="row">
                {data
                  ? data.map((item) => (
                      <PackageCard
                        name={item.name}
                        shortDescription={item.shortDescription}
                        details={item.details}
                        price={item.price}
                        current={false}
                        id={item._id}
                        onSelect={handleSelect}
                      />
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;
