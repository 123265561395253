import React, { Component } from "react";
import { Link } from "react-router-dom";
import auth from "../services/authService";

class SideBar extends Component {
  state = {
    myprofileSubmenu: "d-none",
    settingsSubmenu: "d-none",
  };

  openSubmenu = (submenu) => {
    var changeSubMenu = "d-none";
    if (this.state[submenu] === "d-none") {
      changeSubMenu = "d-block";
    }
    this.setState({ [submenu]: changeSubMenu });
  };

  /*componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && user.current_package && user.current_package.package_id) {
      this.setState({ package_id: user.current_package.package_id });
    }
  }*/

  render() {
    const { myprofileSubmenu, settingsSubmenu } = this.state;
    return (
      <div
        className={`left side-menu ${
          this.props.sideMenu === "true" ? "openMobileMenu" : ""
        }`}
      >
        <button
          type="button"
          className="button-menu-mobile button-menu-mobile-topbar open-left"
        >
          <i className="ion-close" onClick={this.props.openMenu}></i>
        </button>

        <div className="topbar-left">
          <div className="text-center">
            <a href="index.html" className="logo">
              <img src="/assets/images/logo.png" height="45" alt="logo" />
            </a>
          </div>
        </div>

        <div
          className="slimScrollDiv"
          style={{
            position: "relative",
            overflow: "hidden",
            width: "auto",
            height: 908 + "px",
          }}
        >
          <div
            className="sidebar-inner slimscrollleft"
            id="sidebar-main"
            style={{ overflow: "hidden", width: "auto", height: 908 + "px" }}
          >
            <div id="sidebar-menu" className="active">
              <ul>
                <li className="menu-title">Menu</li>

                {auth.getCurrentUser().current_package &&
                auth.getCurrentUser().current_package.package_id ? (
                  <>
                    <li className="has_sub active">
                      <Link
                        to={"/"}
                        className="waves-effect waves-light active"
                      >
                        <i className="mdi mdi-view-dashboard"></i>
                        <span> Dashboard </span>
                      </Link>
                    </li>

                    <li className="has_sub">
                      <Link className="waves-effect" to={"/profile"}>
                        <i className="mdi mdi-account-circle"></i>
                        <span> My Profile </span>{" "}
                        <span className="float-right">
                          <i
                            className={`mdi pointer ${
                              myprofileSubmenu === "d-block"
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }`}
                            onClick={() => this.openSubmenu("myprofileSubmenu")}
                          ></i>
                        </span>
                      </Link>
                      <ul className={`list-unstyled ${myprofileSubmenu}`}>
                        <li>
                          <Link
                            to={{
                              pathname: "/profile",
                              state: { target: "aboutme" },
                            }}
                          >
                            About Me
                          </Link>
                        </li>
                      
                        <li>
                          <Link
                            to={{
                              pathname: "profile",
                              state: { target: "images_gallery" },
                            }}
                          >
                            Photos
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "profile",
                              state: { target: "videos_gallery" },
                            }}
                          >
                            Videos
                          </Link>
                        </li>

                  
                      </ul>
                    </li>

                  

                    <li className="has_sub">
                      <Link
                        className="waves-effect"
                        onClick={() => this.openSubmenu("settingsSubmenu")}
                      >
                        <i className="mdi mdi-settings"></i>
                        <span> Settings </span>{" "}
                        <span className="float-right">
                          <i
                            className={`mdi pointer ${
                              settingsSubmenu === "d-block"
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }`}
                          ></i>
                        </span>
                      </Link>
                      <ul className={`list-unstyled ${settingsSubmenu}`}>
                        <li>
                          <Link to="/settings/update">
                            Update Login Details
                          </Link>
                        </li>
                        <li>
                          <Link to="/settings/change-password">
                            Change password
                          </Link>
                        </li>
           
                        <li>
                          <Link to="/">Orders</Link>
                        </li>
                      </ul>
                    </li>
                  </>
                ) : (
                  ""
                )}

                {auth.getCurrentUser().isAdmin && (
                  <>
                    <li className="has_sub">
                      <Link to="/manage/list">
                        <i className="mdi mdi-code-greater-than-or-equal"></i>
                        <span> Manage Profiles </span>
                      </Link>
                    </li>
                  </>
                )}

                <li className="has_sub">
                  <Link to="/logout">
                    <i className="mdi mdi-power"></i>
                    <span> Logout </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
          <div
            className="slimScrollBar"
            style={{
              background: "#9ea5ab",
              width: 10 + "px",
              position: "absolute",
              top: 0 + "px",
              opacity: "0.4",
              display: "none",
              borderRadius: 7 + "px",
              zIndex: 99,
              right: 1 + "px",
              height: 908 + "px ",
            }}
          ></div>
          <div
            className="slimScrollRail"
            style={{
              width: 10 + "px",
              height: 100 + "%",
              position: "absolute",
              top: 0 + "px",
              display: "none",
              borderRadius: 7 + "px",
              background: "#333333",
              opacity: 0.2,
              zIndex: 90,
              right: 1 + "px",
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default SideBar;
