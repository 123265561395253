import React, { Component } from "react";
import http from "./../services/httpServices";
import config from "./../config.json";

class UsersList extends Component {
  state = { posts: [] };

  async componentDidMount() {
    const { data: posts } = await http.get("s" + config.apiEndpoint);
    this.setState({ posts });
  }
  render() {
    return (
      <div class="page-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <h2 class="page-title">Forms</h2>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  Examples and usage guidelines for form control styles, layout
                  options, and custom components for creating a wide variety of
                  forms.
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Stripped Rows</h5>
                  <p>
                    Use <code>.table-striped</code> to add zebra-striping to any
                    table row within the <code>&lt;tbody&gt;</code>.
                  </p>
                  <div class="table-container">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">First</th>
                          <th scope="col">Last</th>
                          <th scope="col">Handle</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.posts.map((item) => (
                          <tr>
                            <th scope="row">{item.userId}</th>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>Delete</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
    );
  }
}

export default UsersList;
