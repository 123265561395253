import React, { useState } from "react";
import { fnDencrypt } from "./../common/encrypt";

const AppointmentCard = (props) => {
  const getStatusStyle = (status, type) => {
    let result = "";
    if (status === "Pending") {
      if (type === "text") {
        result = "text-success";
      } else if (type === "icon") {
        result = "fa fa-inbox";
      } else {
        result = "4px solid #5b6be8 ";
      }
      return result;
    }

    if (status === "Confirmed") {
      if (type === "text") {
        result = "text-primary";
      } else if (type === "icon") {
        result = "fas fa-calendar-check";
      } else {
        result = "4px solid #59ceb5";
      }
      return result;
    }

    if (status === "Recused") {
      if (type === "text") {
        result = "text-alert";
      } else if (type === "icon") {
        result = "far fa-trash-alt";
      } else {
        result = "4px solid #5b626b";
      }
      return result;
    }

    if (status === "Canceled") {
      if (type === "text") {
        result = "text-alert";
      } else if (type === "icon") {
        result = "far fa-trash-alt";
      } else {
        result = "4px solid #5b626b";
      }
      return result;
    }
  };
  const [editNote, setEditNote] = useState(false);
  const [noteEditing, setNoteEditing] = useState();

  const [editStatus, setEditStatus] = useState(false);

  return (
    <>
      <div id="user-activities" className="tab-pane">
        <div className="">
          <div
            className=""
            style={{
              marginBottom: "10px",
              paddingBottom: "5px",
              borderLeft: getStatusStyle(props.data.status, "border"),
            }}
          >
            <div className="item-info">
              <p className="mb-0 mt-0">
                <span className={getStatusStyle(props.data.status, "text")}>
                  <strong>
                    <i
                      className={getStatusStyle(props.data.status, "icon")}
                    ></i>{" "}
                    {props.data.status}
                  </strong>
                </span>{" "}
                <div className="dropdown d-inline">
                  <i
                    className="btn btn-round dropdown-toggle p-2 "
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  ></i>
                  <div
                    className="dropdown-menu dropdown-menu-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <p
                      className="dropdown-item pointer  pb-0"
                      onClick={() =>
                        props.handleStatus(props.data._id, "Pending")
                      }
                    >
                      Pending
                    </p>
                    <p
                      className="dropdown-item pointer pb-0"
                      onClick={() =>
                        props.handleStatus(props.data._id, "Confirmed")
                      }
                    >
                      Confirmed
                    </p>
                    <p
                      className="dropdown-item pointer pb-0"
                      onClick={() =>
                        props.handleStatus(props.data._id, "Recused")
                      }
                    >
                      Recused
                    </p>
                    <p
                      className="dropdown-item  pointer pb-0"
                      onClick={() =>
                        props.handleStatus(props.data._id, "Canceled")
                      }
                    >
                      Canceled
                    </p>
                  </div>
                </div>
              </p>
              <div className="text-muted">
                <strong>
                  {props.data.starts / 60}:00 -{" "}
                  {(props.data.starts + props.data.period) / 60}:00 |{" "}
                  {props.data.name}
                </strong>
              </div>
              <p className="mb-0 mt-0">
                Mobile Number:{" "}
                <a href="tel:{fnDencrypt(props.data.mobile)}">
                  <u>{fnDencrypt(props.data.mobile)}</u>
                </a>
              </p>
              <p className="mb-0 mt-0">
                Type: <b>{props.data.typeservice}</b>
              </p>
              {props.data.suburb ? (
                <p className="mb-0 mt-0">
                  Suburb: <b>{props.data.suburb}</b>
                </p>
              ) : (
                ""
              )}

              {!editNote ? (
                <>
                  {props.data.notes ? (
                    <p className="mb-0 mt-2">
                      Private Note: {props.data.notes}{" "}
                      <span
                        className="badge badge-light pointer"
                        onClick={() => {
                          setEditNote(true);
                          setNoteEditing(props.data.notes || "");
                        }}
                      >
                        Edit
                      </span>
                    </p>
                  ) : (
                    <p className="mb-0 mt-0">
                      <span
                        className="badge badge-light pointer"
                        onClick={() => setEditNote(true)}
                      >
                        Add a private note
                      </span>
                    </p>
                  )}
                </>
              ) : (
                <>
                  <textarea
                    id="note"
                    name="note"
                    rows="4"
                    cols="40"
                    className="d-block"
                    onChange={(e) => setNoteEditing(e.target.value)}
                  >
                    {noteEditing}
                  </textarea>
                  <button
                    type="button"
                    className="btn btn-primary  mt-1"
                    onClick={() => {
                      props.handleEditNote(props.data._id, noteEditing);
                      setEditNote(false);
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger  ml-2 mt-1"
                    onClick={() => {
                      setEditNote(false);
                      setNoteEditing(!props.data.notes ? props.data.notes : "");
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentCard;
