import React, { useState, useEffect } from "react";
import VerticalBarChart from "./reports/verticalBarChart";
import OneData from "./reports/oneData";
import { getCurrentUser } from "../services/authService";
import { fetchGAByUser } from "../services/reports";

const Dashboard = (props) => {
  const [gaData, setGAData] = useState();
  const [period, setPeriod] = useState("7daysAgo");
  const [profileViews, setProfileViews] = useState();
  const [profileFav, setProfileFav] = useState();
  const [profileBooking, setProfileBooking] = useState();
  const [profileClickToCall, setProfileClickToCall] = useState();

  const getGAData = async () => {
    const user_id = props.user_id || getCurrentUser()._id;
    try {
      /*const ga = await fetchGAByUser(user_id, period);
      if (ga && ga.data && ga.data.results) {
        setGAData(ga.data.results);
      }*/
      const ga = {"_Added_to_favourite":{"total":"5","unique":"2"},"_Removed_from_favourite":{"total":"7","unique":"1"},"_Show_mobile_number":{"total":"6","unique":"4"},"_View_Profile":{"total":"25","unique":"9"}}
      setGAData(ga);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGAData();
  }, []);

  useEffect(() => {
    setGAData();
    getGAData();
  }, [period]);

  useEffect(() => {
    //profile view chart/data
    if (gaData && gaData["_View_Profile"]) {
      setProfileViews({
        labels: ["All Views", "Unique Views"],
        datasets: [
          {
            label: ["Number of Views"],
            data: [gaData._View_Profile.total, gaData._View_Profile.unique],
            backgroundColor: ["#16927b", "#16927b"],
          },
        ],
      });
    }
    // Add to Fav
    if (
      gaData &&
      gaData["_Added_to_favourite"] &&
      gaData["_Removed_from_favourite"]
    ) {
      setProfileFav({
        labels: ["Added to Favourite", "Removed from Favourite"],
        datasets: [
          {
            label: ["Favourite"],
            data: [
              gaData._Added_to_favourite.unique,
              gaData._Removed_from_favourite.unique,
            ],
            backgroundColor: ["#16927b", "#16927b"],
          },
        ],
      });
    }

    if (
      gaData &&
      gaData["_Added_to_favourite"] &&
      !gaData["_Removed_from_favourite"]
    ) {
      setProfileFav({
        labels: ["Added to Favourite", "Removed from Favourite"],
        datasets: [
          {
            label: ["Favourite"],
            data: [gaData._Added_to_favourite.unique],
            backgroundColor: ["#16927b", "#16927b"],
          },
        ],
      });
    }

    if (
      gaData &&
      !gaData["_Added_to_favourite"] &&
      gaData["_Removed_from_favourite"]
    ) {
      setProfileFav({
        labels: ["Added to Favourite", "Removed from Favourite"],
        datasets: [
          {
            label: ["Favourite"],
            data: [0, gaData._Removed_from_favourite.unique],
            backgroundColor: ["#16927b", "#16927b"],
          },
        ],
      });
    }

    // Booking
    if (gaData && gaData["_Booking_requested"]) {
      setProfileBooking(gaData._Booking_requested.total);
    }

    if (gaData && !gaData["_Booking_requested"]) {
      setProfileBooking("-");
    }

    

    if (gaData && !gaData["_Show_mobile_number"]) {
      setProfileClickToCall("-");
    }
  }, [gaData]);

  /*
  {
    labels: ["All Views", "Unique Views"],
    datasets: [
      {
        label: ["Number of Views"],
        data: [102, 19],
        backgroundColor: ["#16927b", "#16927b"],
      },
    ],
  }*/

  return (
    <div className="content-page">
      <div className="content">
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="page-title">Dashboard</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <div class="row">
                      <div class="col-12 text-lg-right">
                        <div class="btn-group mr-2">
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={period === "7daysAgo" ? true : false}
                            onClick={() => setPeriod("7daysAgo")}
                          >
                            Last 7 Days
                          </button>

                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={period === "15daysAgo" ? true : false}
                            onClick={() => setPeriod("15daysAgo")}
                          >
                            Last 15 Days
                          </button>

                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={period === "30daysAgo" ? true : false}
                            onClick={() => setPeriod("30daysAgo")}
                          >
                            Last 30 Days
                          </button>
                        </div>{" "}
                        <div class="btn-toolbar" role="toolbar"></div>
                      </div>
                    </div>

                    <hr className="mb-4" />

                    <div className="row">
                      {!gaData && (
                        <>
                          <img
                            src="./assets/images/processing.gif"
                            className="mx-auto "
                          />
                        </>
                      )}
                      {gaData && (
                        <>
                          <div className="col-lg-4">
                            {gaData && !profileViews && (
                              <OneData title="Profile Results" value="-" />
                            )}
                            {profileViews && (
                              <VerticalBarChart
                                data={profileViews}
                                title={"Profile Results"}
                              />
                            )}
                          </div>
                         
                          <div className="col-lg-4">
                      
                              <OneData
                                title="Subscriptions"
                                value={12}
                                description="orders"
                              />
                 
                          </div>
                          <div className="col-lg-4">
                      
                              <OneData
                                title="Revenue"
                                value={"$320.00"}
                                description="revenue"
                              />
                 
                          </div>
                          
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
