import React, { Component } from "react";
import _ from "lodash";
import UploadPhotos from "./uploadFotos";
import PhotosImageGallery from "./photosImageGallery";
import uploadservice from "../../services/uploadService";
import { getCurrentUser } from "../../services/authService";
import Modal from "../common/modal";
import PhotosEditor from "./PhotosEditor";
import { ToastContainer, toast } from "react-toastify";
import { checkLimits } from "../../services/admin/profileAdminServices";

import "react-toastify/dist/ReactToastify.css";

class Photos extends Component {
  state = {
    images: this.props.data,
    editModal: "",
    limit: 0,
  };

  updateImages = (data, section) => {
    this.setState({ images: data });
    this.props.handleDataUpdate(data, section);
    this.handleLimit();
  };

  handleDelete = async (filename) => {
    let listOfImages = this.state.images;
    const index = _.findIndex(listOfImages, function (o) {
      return o.original === filename;
    });

    if (index !== -1) {
      try {
        await uploadservice.deletefile(filename, this.props.profileUserId);
        listOfImages.splice(index, 1);
        this.updateImages(listOfImages, "images_gallery");
        toast.success("Image deleted sucessfully! 🥳");
        this.handleLimit();
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.warning(ex.response.data);
        } else {
          toast.error(ex.toString());
        }
      }
    }
  };

  /* Edit functions */
  handleEditImage = (path, image) => {
    const editImage = (
      <PhotosEditor
        path={path}
        image={image}
        processPhoto={this.handleProcessPhoto}
        close={this.handleClose}
        profileUserId={this.props.profileUserId}
      />
    );

    this.setState({
      editModal: (
        <Modal
          title="Edit Image"
          close={this.handleClose}
          component={editImage}
        />
      ),
    });
  };

  handleProcessPhoto = (image, status) => {
    let listOfImages = this.state.images;
    const index = _.findIndex(listOfImages, function (o) {
      return o.original === image["original"];
    });

    if (index !== -1) {
      listOfImages[index] = _.assign(listOfImages[index], {
        processing: status,
      });
      this.setState({ images: listOfImages });
    }
  };

  handleClose = () => {
    this.setState({ editModal: "" });
  };

  handleLimit = async () => {
    const user = getCurrentUser();
    const totalImages = this.state.images.length;
    let limitsList = [];
    if (user.isAdmin) {
      limitsList = await checkLimits(this.props.profileUserId);
      limitsList = limitsList.data.current_package.limits;
    } else {
      limitsList = user.current_package.limits;
    }
    console.log("limitsList", limitsList);
    if (limitsList) {
      const filterlimit = _.filter(limitsList, function (o) {
        return o.k === "Photos";
      });

      if (filterlimit.length > 0) {
        this.setState({ limit: parseInt(filterlimit[0].v[0]) - totalImages });
      }
    }
  };

  componentDidMount = () => {
    this.handleLimit();
  };

  render() {
    return (
      <>
        {this.state.editModal}
        <div className="col-md-12  profile-detail">
          <div className="">
            <h5 className="mb-0 py-2 color-green">
              {" "}
              <i className="ti-image"></i> Photos
            </h5>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 align-self-center">
          <UploadPhotos
            limit={this.state.limit}
            handleDataUpdate={this.updateImages}
            profileUserId={this.props.profileUserId}
          />
        </div>
        <div className="col-12 m-3 px-4">
          <div className="row">
            <PhotosImageGallery
              images={this.state.images}
              handleDelete={this.handleDelete}
              editImage={this.handleEditImage}
              handleDataUpdate={this.updateImages}
              profileUserId={this.props.profileUserId}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Photos;
