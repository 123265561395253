import React, { Component } from "react";
import { Slider } from "@material-ui/core";
import Cropper from "react-easy-crop";
import uploadservice from "../../services/uploadService";

class PhotosEditor extends Component {
  state = {
    imageSrc: `${this.props.path}/${this.props.image["original"]}`,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 3 / 4,
    rotation: 0,
    saveBtn: "",
    disableBtn: false,
    btClass: "btn-primary",
    croppedAreaPixels: {},
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    if (zoom > 2) {
      zoom = 2;
    }
    this.setState({ zoom });
  };

  onRotateChange = () => {
    let rotation = this.state.rotation;
    rotation = rotation + 90;
    if (rotation >= 360) {
      rotation = 0;
    }

    let aspect = 0.75;
    if (this.state.aspect === 0.75) {
      aspect = 1.3333333333333333;
    }

    this.setState({
      rotation,
      aspect,
      crop: { x: 0, y: 0 },
      croppedAreaPixels: {},
      zoom: 1,
      imageSrc: `${this.props.path}/${this.props.image["original"]}`,
    });
  };

  onMediaLoaded = (mediaSize) => {
    // {width, height, naturalWidth, naturalHeight}
    if (this.props.avatar && this.props.avatar === true) {
      this.setState({ aspect: 1 });
    } else {
      if (mediaSize["width"] > mediaSize["height"]) {
        this.setState({ aspect: 1.3333333333333333 });
      } else {
        this.setState({ aspect: 0.75 });
      }
    }
  };

  onSave = async () => {
    const { croppedAreaPixels, zoom, rotation } = this.state;
    const { image } = this.props;
    try {
      this.props.processPhoto(this.props.image, true);
      this.setState({
        saveBtn: "Processing...",
        disableBtn: true,
        btClass: "btn-warning",
      });
      let avatar = false;
      if (this.props.avatar && this.props.avatar === true) {
        avatar = true;
      }

      await uploadservice.editImage(
        croppedAreaPixels,
        zoom,
        rotation,
        image,
        avatar,
        this.props.profileUserId
      );
      this.setState({
        saveBtn: "Sucessfully processed! 🥳",
        disableBtn: true,
        btClass: "btn-success",
      });
    } catch (ex) {
      this.setState({
        saveBtn: "Process failed!",
        disableBtn: true,
        btClass: "btn-danger",
      });
      if (ex.response && ex.response.status === 400) {
        //toast.warning(ex.response.data);
        console.log(ex.response.data);
      } else {
        //toast.error(ex.toString());
        console.log(ex);
      }
    } finally {
      setTimeout(() => {
        this.setState({
          saveBtn: "",
          disableBtn: false,
          btClass: "btn-primary",
        });
      }, 2000);
      this.props.processPhoto(this.props.image, false);
    }
  };

  onClose = () => {
    this.props.close();
  };

  render() {
    return (
      <>
        <div class="modal-body">
          <div className="boxEditor">
            <div className="crop-container">
              <Cropper
                image={this.state.imageSrc}
                crop={this.state.crop}
                rotation={this.state.rotation}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                onRotateChange={this.onRotateChange}
                onMediaLoaded={this.onMediaLoaded}
              />
            </div>
            <div className="controls">
              <i class="mdi mdi-account-search p-2"></i>
              <Slider
                value={this.state.zoom}
                min={1}
                max={2}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => this.onZoomChange(zoom)}
                classes={{ container: "slider" }}
              />
              <i class="mdi mdi-rotate-right-variant p-2"></i>
              {/*<button
            value={this.state.rotation}
            min={0}
            max={360}
            step={90}
            aria-labelledby="Rotation"
            classes={{ container: "slider" }}
            onChange={(e, rotation) => this.onRotateChange(rotation)}
          />*/}
              <button
                type="button"
                class="btn btn-light p-1"
                onClick={(e, rotation) => this.onRotateChange()}
              >
                Rotate
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={this.onClose}
          >
            Close
          </button>
          <button
            type="button"
            class={`btn ${this.state.btClass}`}
            onClick={this.onSave}
            disabled={this.state.disableBtn}
          >
            {this.state.saveBtn || "Save"}
          </button>
        </div>
      </>
    );
  }
}

export default PhotosEditor;
