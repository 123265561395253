import http from "./httpServices";
import { apiUrl } from "../config.json";
import { getCurrentUser } from "./authService";

const apiEndpoint = `${apiUrl}/booking`;

export async function fetchAgenda(period) {
  const currentUser = getCurrentUser();
  return await http.post(`${apiEndpoint}/agenda`, {
    user_id: currentUser._id,
    period,
  });
}

export async function setStatus(appointment_id, status) {
  return await http.put(`${apiEndpoint}/status`, { appointment_id, status });
}

export async function setNotes(appointment_id, notes) {
  return await http.put(`${apiEndpoint}/notes`, { appointment_id, notes });
}

export default { fetchAgenda, setStatus, setNotes };
