import React from "react";

const PackageCard = (props) => {
  return (
    <div class="col-md-6 col-xl-4">
      <div class="card m-b-30">
        <div class="card-body row">
          <div class="col-12 card-title align-self-center mb-0 text-center mt-4">
            <h5>{props.name.toUpperCase()}</h5>
            <p class="m-0">{props.shortDescription}</p>
            <hr className="w-25" />
          </div>
        </div>
        <ul class="list-group list-group-flush text-center">
          {props.details.map((item) => (
            <li class="list-group-item">{item}</li>
          ))}
        </ul>
        <div class="card-body text-center">
          {!props.current ? (
            <div class="btn-group btn-group-sm">
              <span
                onClick={() => props.onSelect(props.id)}
                class="btn btn-secondary tooltips"
                data-placement="top"
                data-toggle="tooltip"
                data-original-title="Delete"
              >
                Select
              </span>
              <span
                onClick={() => props.onSelect(props.id)}
                class="btn btn-primary tooltips"
                data-placement="top"
                data-toggle="tooltip"
                data-original-title="Edit"
              >
                {props.price && props.price !== 0 ? `$${props.price}` : "FREE"}{" "}
                <i class="ti-angle-double-right"></i>
              </span>
            </div>
          ) : (
            <span
              class="btn btn-secondary disabled"
              style={{ cursor: "default" }}
            >
              Selected
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
