import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";

import ProfileTabs from "./../../profile/profileTabs";
import ProfileAvatar from "./../../profile/profileAvatar";
import profileAdmin from "../../../services/admin/profileAdminServices";
import Modal from "../../common/modal";
import ApprovalModal from "./approvalModal";

function AdminProfileEdit(props) {
  const { id: profile_id } = useParams();
  const [profiledata, setData] = useState();
  const [mobileNumberValidation, setMobileNumberValidation] = useState([]);
  const [approval, setApproval] = useState();

  const fetchData = async () => {
    try {
      let response = await profileAdmin.fetchProfile(profile_id);
      if (response && response.data && response.data._id) {
        setData(response.data);
      } else {
        //window.location = "/";
      }
    } catch (ex) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updatedData = (updated, section) => {
    let newData = profiledata;
    console.log(newData);
    newData = _.set(newData, section, updated);
    setData(newData);
  };

  const handlePublish = async () => {
    try {
      await profileAdmin.publish(profiledata);
      toast.success("Profile published!");
      fetchData();
    } catch (error) {
      console.log(error);
      toast.warning("Error!");
    }
  };

  const handleApprove = (status) => {
    setApproval(
      <Modal
        title="Profile Approval"
        close={handleCloseModal}
        component={
          <ApprovalModal
            status={status}
            handleApprovalStatus={sendApprovalStatus}
          />
        }
      />
    );
  };

  const sendApprovalStatus = async (status, comments) => {
    try {
      await profileAdmin.sendApprovalStatus(
        status,
        comments,
        profile_id,
        profiledata.user_id
      );
      handleCloseModal();
      if (status === "approve") {
        handlePublish();
      }
    } catch (error) {
      console.log(error);
      toast.warning("Error!");
    }
  };

  const handleCloseModal = () => {
    setApproval("");
  };

  return (
    <>
      <ToastContainer />
      {approval}
      <div className="content-page">
        <div className="content">
          <div className="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-title-box">
                    <div class="float-right">
                      <div class="dropdown">
                        {profiledata && profiledata.review_requested ? (
                          <>
                            <button
                              class="btn btn-danger btn-round px-3 mr-2"
                              type="button"
                              id=""
                              onClick={() => handleApprove("reject")}
                            >
                              <i class="mdi mdi-thumb-down mr-1"></i> Reject
                            </button>
                            <button
                              class="btn btn-success btn-round px-3 mr-2"
                              type="button"
                              id=""
                              onClick={() => handleApprove("approve")}
                            >
                              <i class="mdi mdi-thumb-up mr-1"></i> Approve
                            </button>
                          </>
                        ) : (
                          ""
                        )}

                        <button
                          class="btn btn-secondary btn-round px-3"
                          type="button"
                          id=""
                          onClick={handlePublish}
                        >
                          <i class="mdi mdi-share mr-1"></i> Publish
                        </button>
                      </div>
                    </div>
                    <h4 className="page-title">Profile Management</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                {profiledata ? (
                  <>
                    <div className="col-lg-12 col-xl-3">
                      <ProfileAvatar
                        nickname={
                          profiledata && profiledata["nickname"]
                            ? profiledata["nickname"]
                            : false
                        }
                        avatar={
                          profiledata && profiledata["avatar"]
                            ? profiledata["avatar"]
                            : {}
                        }
                        handleDataUpdate={updatedData}
                        profileUserId={profiledata["user_id"]}
                      />
                    </div>

                    <div className="col-lg-12 col-xl-9">
                      <ProfileTabs
                        data={profiledata}
                        handleDataUpdate={updatedData}
                        target={
                          props.location.state && props.location.state["target"]
                            ? props.location.state["target"]
                            : ""
                        }
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminProfileEdit;
