import React from "react";
import { Link, Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import user from "../services/signUpService";
import auth from "../services/authService";

class ResetPassword extends Form {
  state = {
    data: {
      password: "",
      confirmPassword: "",
    },
    errors: {},
    failed: "",
  };

  schema = {
    password: Joi.string().min(5).max(255).required().label("Password"),
    confirmPassword: Joi.string()
      .min(5)
      .max(255)
      .required()
      .label("Confirm Password"),
  };
  //this.props.match.params.id

  doSubmit = async () => {
    if (this.state.data["password"] !== this.state.data["confirmPassword"]) {
      return this.setState({
        errors: { confirmPassword: "'Confirm Password': must match password" },
      });
    }

    try {
      const password = this.state.data.password;
      const recover_id = this.props.match.params.id;
      await user.resetPassword(password, recover_id);
      window.location = "/profile";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.erros };
        errors.email = ex.response.data;
        this.setState({ failed: ex.response.data });
      }
    }
  };

  componentDidMount() {
    //document.getElementById("body").className = "login-page sign-in loaded";
  }

  componentWillUnmount() {
    //document.getElementById("body").className = "";
  }

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/profile" />;
    return (
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center mt-0">
              <a href="index.html" className="logo logo-admin">
                <img src="/assets/images/logo.png" height="45" alt="logo" />
              </a>
            </h3>

            <h6 className="text-center">Reset your password</h6>

            <div className="p-3">
              {this.state.failed === "" ? (
                <>
                  <p className="my-3">
                    We recommend that you use a combination of upper case and
                    lower case letters as well as numbers.
                  </p>

                  <form
                    className="form-horizontal"
                    onSubmit={this.handleSubmit}
                  >
                    {this.renderInput("password", "Password", "password")}
                    {this.renderInput(
                      "confirmPassword",
                      "Confirm Password",
                      "password"
                    )}
                    {this.renderButton("Reset Password", "w-100")}
                  </form>
                </>
              ) : (
                <p className="border rounded p-3 bg-light">
                  <strong>Erros:</strong> {this.state.failed}
                </p>
              )}

              <p className="mt-4">
                <Link to="/login">Back to login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
