import React from "react";
import Joi from "joi-browser";
import Form from "./../common/form";
import user from "../../services/signUpService";
import _ from "lodash";
import { getCurrentUser } from "../../services/authService";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

class ChangePassword extends Form {
  state = {
    data: {
      password: "",
      newpassword: "",
      confirmNewPassword: "",
    },
    errors: {},
    limit: 10,
  };

  schema = {
    password: Joi.string().min(8).max(255).label("Password"),
    newpassword: Joi.string().min(8).max(255).label("New Password"),
    confirmNewPassword: Joi.string()
      .min(8)
      .max(255)
      .label("Confirm New Password"),
  };

  doSubmit = async () => {
    try {
      if (this.state.data.newpassword !== this.state.data.confirmNewPassword) {
        toast.warning(
          "'Confirm New Password' doesn't match with 'New Password'"
        );
        return;
      }

      let data = this.state.data;
      delete data.confirmNewPassword;
      await user.changePassword(data);
      toast.success("Your password has been updated! 🥳");
      this.setState({
        data: {
          password: "",
          newpassword: "",
          confirmNewPassword: "",
        },
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  render() {
    return (
      <div class="content-page">
        <div class="content">
          <div class="page-content-wrapper">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <h4 class="page-title">Change Password</h4>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="card m-b-30">
                    <div class="card-body">
                      <form onSubmit={this.handleSubmit}>
                        <div className="row">
                          {this.renderInput(
                            "password",
                            "Password",
                            "password",
                            "col-12"
                          )}
                          {this.renderInput(
                            "newpassword",
                            "New Password",
                            "password",
                            "col-12"
                          )}
                          {this.renderInput(
                            "confirmNewPassword",
                            "Confirm New Password",
                            "password",
                            "col-12"
                          )}
                        </div>
                        {this.renderButton("Update Password", "w-100")}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
