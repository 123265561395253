import React, { useState, useEffect } from "react";
import TabLine from "../common/tabLine";
import AboutMe from "./aboutMe";
import Photos from "./photos";
import Videos from "./videos";

function ProfileTabs(props) {
  const [tab, setTab] = useState([]);

  useEffect(() => {
    switch (props.target) {
      case "aboutme":
        setTab([
          <AboutMe
            data={props.data["aboutme"]}
            handleDataUpdate={props.handleDataUpdate}
            profileUserId={props.data["user_id"]}
          />,
          "aboutme",
        ]);
        break;
      case "images_gallery":
        setTab([
          <Photos
            data={props.data["images_gallery"]}
            limit={props.data["package"]}
            handleDataUpdate={props.handleDataUpdate}
            profileUserId={props.data["user_id"]}
          />,
          "images_gallery",
        ]);
        break;

      case "videos_gallery":
        setTab([
          <Videos
            data={props.data["videos_gallery"]}
            handleDataUpdate={props.handleDataUpdate}
            profileUserId={props.data["user_id"]}
          />,
          "videos_gallery",
        ]);
        break;

      
      default:
        setTab([
          <AboutMe
            data={props.data["aboutme"]}
            handleDataUpdate={props.handleDataUpdate}
            profileUserId={props.data["user_id"]}
          />,
          "aboutme",
        ]);
        break;
    }
  }, [props.target]);

  return (
    <div className="card m-b-30">
      <div className="card-header profile-tabs pb-0">
        <ul className="nav nav-tabs">
          <TabLine
            icon="ti-themify-favicon"
            name="About me"
            set={setTab}
            comp={
              <AboutMe
                data={props.data["aboutme"]}
                handleDataUpdate={props.handleDataUpdate}
                profileUserId={props.data["user_id"]}
              />
            }
            selected={tab[1] === "About me" ? "1" : ""}
          />
         
          <TabLine
            icon="ti-image"
            name="Photos"
            set={setTab}
            comp={
              <Photos
                handleDataUpdate={props.handleDataUpdate}
                data={props.data["images_gallery"]}
                profileUserId={props.data["user_id"]}
              />
            }
            selected={tab[1] === "Photos" ? "1" : ""}
          />

          <TabLine
            icon="ti-control-play"
            name="Videos"
            set={setTab}
            comp={
              <Videos
                handleDataUpdate={props.handleDataUpdate}
                data={props.data["videos_gallery"]}
                profileUserId={props.data["user_id"]}
              />
            }
            selected={tab[1] === "Videos" ? "1" : ""}
          />
        </ul>
      </div>
      <div className="card-body">
        <div className="">
          <div className="tab-content">
            <div className="tab-pane active pt-3" id="about">
              <div className="row justify-content-center">{tab[0]}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileTabs;
