import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TopBar from "./components/topBar";
import SideBar from "./components/sideBar";
import Dashboard from "./components/dashboard";
import Profile from "./components/profile";
import Login from "./components/login";
import SignUp from "./components/signUp";
import Logout from "./components/logout";
import UsersList from "./components/usersList";
import auth from "./services/authService";
import ProtectedRoute from "./components/common/protectedRoute";
import ForgotPassword from "./components/forgotPassword";
import ActiveAccount from "./components/activeAccount";
import Packages from "./components/packages";
import ResetPassword from "./components/resetPassword";
import ChangePassword from "./components/settings/changePassword";
import ChangeBasicDetails from "./components/settings/changeBasicDetails";
import ManageBooking from "./components/booking/manageBooking";
//Admin
import AdminProfileList from "./components/admin/profiles/list";
import AdminProfileEdit from "./components/admin/profiles/edit";


import "./App.css";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  state = {
    sideMenu : ""
  };

  

  openMenu = () => {
    if (this.state.sideMenu === "" || this.state.sideMenu === "false") {
      this.setState({sideMenu : "true" })
    } else {
      this.setState({sideMenu : "false"})
    }
  }

  componentDidMount() {
    try {
      const user = auth.getCurrentUser();
      this.setState({ user });
    } catch (ex) {}
    
  }



  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        {this.state.user ? (
          <>
            <TopBar openMenu={this.openMenu}/>
            <SideBar sideMenu={this.state.sideMenu} openMenu={this.openMenu}/>
          </>
        ) : (
          ""
        )}
        <Switch>
          <ProtectedRoute path="/manage/list" component={AdminProfileList} />
          <ProtectedRoute path="/manage/edit/:id" component={AdminProfileEdit} />
          
          <Route path="/login" component={Login} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/logout" component={Logout} />
          <Route path="/packages" component={Packages} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/active-account/:id" component={ActiveAccount} />
          <Route path="/reset-password/:id" component={ResetPassword} />
          <ProtectedRoute path="/users-list" component={UsersList} />
          <ProtectedRoute path="/profile" component={Profile} />
          <ProtectedRoute path="/settings/change-password" component={ChangePassword} />
          <ProtectedRoute path="/settings/update" component={ChangeBasicDetails} />
          <ProtectedRoute path="/booking" component ={ManageBooking} />
          <ProtectedRoute path="/" component={Dashboard} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
