import React from "react";

const SingleSelect = ({
  label,
  name,
  className,
  values,
  selected,
  onChange,
  ...rest
}) => {
  function option(value) {
    return <option value={value}>{value}</option>;
  }

  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={label}>{label}</label>

      <select
        className="select2 form-control mb-3 custom-select"
        style={{ width: 100 + "%", height: 36 + "px" }}
        onChange={onChange}
        name={name}
        value={selected}
      >
        <option>Select</option>
        {values.map((item) => {
          return option(item["v"]);
        })}
      </select>
    </div>
  );
};

export default SingleSelect;
