import http from "./httpServices";
import { apiUrl } from "../config.json";

const apiEndpoint = `${apiUrl}/packages`;

export async function fetchPackages() {
  return await http.get(apiEndpoint);
}

export async function setPackages(package_id) {
  return await http.post(apiEndpoint + "/set", { package_id });
}

export default { fetchPackages, setPackages };
