import React from "react";

const InputDateIcon = ({
  label,
  name,
  error,
  className,
  icon,
  onChange,
  value,
  ...rest
}) => {
  return (
    <div className={`form-group ${className}`}>
      {label ? <label htmlFor="email">{label}</label> : ""}
      <div>
        <div class="input-group">
          <div class="input-group-append bg-custom b-0">
            <span class="input-group-text">
              <i class={icon}></i>
            </span>
          </div>
          <input
            name={name}
            id={name}
            onChange={onChange}
            placeholder={"dd/mm/yyyy"}
            type="date"
            class="form-control"
            value={value}
            {...rest}
          />
        </div>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default InputDateIcon;
