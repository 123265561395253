import React from "react";

function OneData(props) {
  return (
    <div className="mb-3" style={{ height: "260px" }}>
      <h3 className="align-center">{props.title}</h3>
      <div className="container border" style={{ height: "90%" }}>
        <div class="row" style={{ height: "100%" }}>
          <div className="col align-self-center p-4">
            <h2 className="text-center">{props.value} {props.description}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneData;
