import React from "react";

function TabLine(props) {
  return (
    <li className={`nav-item my-2 ${props.selected ? "color-green" : ""}`}>
      <span
        onClick={() => props.set([props.comp, props.name])}
        className="px-3 pointer"
      >
        <i className={`${props.icon} mr-2`}></i>
        {props.name}
      </span>
    </li>
  );
}

export default TabLine;
