import React, { useState, useEffect } from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import adminProfiles from "../../../services/admin/profileAdminServices";
import { limitResults, imageFolder } from "../../../config.json";
import Pagination from "../../common/paginations";

import "react-toastify/dist/ReactToastify.css";

const AdminProfileList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [profiles, setProfiles] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const list = await adminProfiles.list(limitResults, currentPage);
        console.log(list);
        setProfiles(list.data);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.warning(ex.response.data);
        } else {
          toast.error(ex.toString());
        }
      }
    }
    fetchData();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <ToastContainer />
      <div className="content-page">
        <div className="content">
          <div className="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-title-box">
                    <h4 className="page-title">Manage Website Profiles</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 col-xl-12">
                  <div className="card m-b-30">
                    <div className="card-body">
                      <div className="table-responsive">
                        <div className="row">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th
                                  className="pl-3"
                                  style={{ width: "75px" }}
                                ></th>
                                <th>Name</th>
                                <th>Package</th>
                                <th>Status</th>
                                <th>Review Requested</th>
                                <th style={{ width: "110px" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {profiles &&
                                profiles.data &&
                                profiles.data.map((item) => (
                                  <tr>
                                    <th scope="row" className="pl-3">
                                      {item.avatar && item.avatar.thumbnail ? (
                                        <img
                                          src={`${imageFolder}/${item.user_id}/${item.avatar.thumbnail}`}
                                          width="60px"
                                          className="rounded-circle"
                                        />
                                      ) : (
                                        <img
                                          src="/assets/images/favicon.ico"
                                          width="60px"
                                          className="rounded-circle"
                                        />
                                      )}
                                    </th>
                                    <td>{item.nickname}</td>
                                    <td>
                                      {item.user &&
                                      item.user[0] &&
                                      item.user[0].current_package &&
                                      item.user[0].current_package.package_name
                                        ? item.user[0].current_package
                                            .package_name
                                        : "-"}
                                    </td>
                                    <td>
                                      <span>
                                        {item.user &&
                                        item.user[0] &&
                                        item.user[0].current_package &&
                                        item.user[0].current_package
                                          .package_name
                                          ? item.user[0].current_package.status
                                          : "-"}
                                      </span>
                                    </td>
                                    <td>
                                      {item.review_requested
                                        ? moment(item.review_requested).format(
                                            "DD/MM/YYYY hh:mm"
                                          )
                                        : "-"}
                                    </td>
                                    <td>
                                      <div
                                        class="btn-group btn-group-sm"
                                        style={{ float: "none" }}
                                      >
                                        <Link
                                          type="button"
                                          class="tabledit-edit-button btn btn-sm btn-light"
                                          style={{
                                            float: "none",
                                            margin: "5px",
                                          }}
                                          to={`edit/${item._id}`}
                                        >
                                          <span class="ti-pencil"></span> Edit
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {profiles.totalPages && (
                          <Pagination
                            currentPage={currentPage}
                            totalPages={profiles.totalPages}
                            handlePageChange={handlePageChange}
                            totalFound={profiles.n}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProfileList;
