import React from "react";
import { Link, Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import user from "../services/signUpService";
import auth from "../services/authService";

class ForgotPassword extends Form {
  state = {
    data: { email: "" },
    errors: {},
    requestSent: "",
  };

  schema = {
    email: Joi.string().min(5).max(255).required().email(),
  };

  doSubmit = async () => {
    try {
      const forgot = await user.forgot(this.state.data);
      this.setState({
        requestSent:
          forgot.data ||
          "Error! Please try again or contact the website administrator.",
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.erros };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  componentDidMount() {
    //document.getElementById("body").className = "login-page sign-in loaded";
  }

  componentWillUnmount() {
    //document.getElementById("body").className = "";
  }

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/profile" />;
    return (
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center mt-0">
              <a href="index.html" className="logo logo-admin">
                <img src="/assets/images/logo.png" height="45" alt="logo" />
              </a>
            </h3>

            <h6 className="text-center">Reset your password</h6>

            <div className="p-3">
              {this.state.requestSent === "" ? (
                <>
                  <p className="my-3">
                    Enter the email you use to login to My Own VR and we'll send
                    you a link to get you back into your account.
                  </p>

                  <form
                    className="form-horizontal"
                    onSubmit={this.handleSubmit}
                  >
                    {this.renderInput("email", "Your Email", "text")}
                    {this.renderButton("Login", "w-100")}
                  </form>
                </>
              ) : (
                <p className="border rounded p-3 bg-light">
                  {this.state.requestSent}
                </p>
              )}
              <p className="mt-4">
                <Link to="/login">Back to login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
