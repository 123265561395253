import crypto from "crypto";
import { CRYPTOKEY, CRYPTOALGORITHM } from "../../config.json";

export function fnEncrypt(a) {
  var mykey = crypto.createCipher(CRYPTOALGORITHM, CRYPTOKEY);
  var mystr = mykey.update(a, "utf8", "hex");
  return (mystr += mykey.final("hex"));
}

export function fnDencrypt(a) {
  var mykey = crypto.createDecipher(CRYPTOALGORITHM, CRYPTOKEY);
  var mystr = mykey.update(a, "hex", "utf8");
  return (mystr += mykey.final("utf8"));
}

export default {
  fnEncrypt,
  fnDencrypt,
};
