import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import ProfileTabs from "./profile/profileTabs";
import profile from "../services/profileService";
import auth from "../services/authService";
import ProfileAvatar from "./profile/profileAvatar";
import ProfileContactCard from "./profile/profileContactCard";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function Profile(props) {
  const [profiledata, setData] = useState();
  const [mobileNumberValidation, setMobileNumberValidation] = useState([]);

  const fetchData = async () => {
    try {
      let response = await profile.fetchProfile();
      if (response && response.data && response.data._id) {
        setData(response.data);
      } else {
        window.location = "/packages";
      }
    } catch (ex) {
      //auth.logout();
      //window.location = "/";
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updatedData = (updated, section) => {
    let newData = profiledata;
    newData = _.set(newData, section, updated);
    setData(newData);
  };

  const handleAvailable = (status) => {
    let newData = profiledata;
    newData["available"] = status;
    setData({ ...newData });
  };

  const handleReview = async () => {
    try {
      let response = await profile.requestReview();
      toast.success("You request has been done!");
      let update = profiledata;
      update.review_requested = response.data;
      setData({ ...update });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  const handleCancelReview = async () => {
    try {
      let response = await profile.cancelRequestReview();
      toast.success("You request has been removed!");
      let update = profiledata;
      delete update.review_requested;
      setData({ ...update });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="content-page">
        <div className="content">
          <div className="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-title-box">
                    <h4 className="page-title">Profile</h4>
                  </div>
                </div>
              </div>
              {mobileNumberValidation.map((item) => item)}
              <div className="row">
                {profiledata ? (
                  <div className="col-lg-12 col-xl-3">
                    <ProfileAvatar
                      nickname={
                        profiledata && profiledata["nickname"]
                          ? profiledata["nickname"]
                          : false
                      }
                      available={
                        profiledata && profiledata["available"]
                          ? profiledata["available"]
                          : false
                      }
                      avatar={
                        profiledata && profiledata["avatar"]
                          ? profiledata["avatar"]
                          : {}
                      }
                      handleDataUpdate={updatedData}
                      handleAvailable={handleAvailable}
                      profileUserId={profiledata.user_id}
                    />

                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="card m-b-30 contact">
                          <div className="card-body">
                            {!profiledata.review_requested ? (
                              <>
                                <button
                                  class="btn btn-primary w-100"
                                  onClick={handleReview}
                                >
                                  Submit profile for review
                                </button>
                                <p className="mt-2">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Phasellus vel ante nec justo
                                  mollis sodales lobortis et tortor. Ut
                                  efficitur sem sit amet ante fringilla egestas.
                                </p>
                              </>
                            ) : (
                              <div className="text-center">
                                <p className="bg-success p-2 w-100 mb-0 rounded text-white">
                                  Requested review at
                                  <br />
                                  {moment(profiledata.review_requested).format(
                                    "DD/MM/YYYY hh:mm"
                                  )}
                                </p>
                                <p
                                  onClick={handleCancelReview}
                                  className="mt-2 mb-0 pointer"
                                >
                                  Cancel request
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                  
                  </div>
                ) : (
                  ""
                )}

                {profiledata ? (
                  <div className="col-lg-12 col-xl-9">
                    <ProfileTabs
                      data={profiledata}
                      handleDataUpdate={updatedData}
                      target={
                        props.location.state && props.location.state["target"]
                          ? props.location.state["target"]
                          : ""
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
