import http from "./../httpServices";
import { apiUrl } from "../../config.json";
import auth from "./../authService";

const apiEndpoint = `${apiUrl}/admin/profiles`;

http.setJwt(auth.getJwt());

export async function list(limit, page) {
  return await http.get(`${apiEndpoint}/list/${limit}/${page}`);
}

export async function fetchProfile(id) {
  return await http.get(`${apiEndpoint}/${id}`);
}

//TEMP
export async function publish(data) {
  return await http.post(apiEndpoint + `/publish/`, data);
}

export async function sendApprovalStatus(
  status,
  comments,
  profile_id,
  user_id
) {
  return await http.post(apiEndpoint + `/approval/${profile_id}/${status}`, {
    comments,
    user_id,
  });
}

export async function checkLimits(user_id) {
  return await http.get(`${apiEndpoint}/checklimits/${user_id}`);
}

export default {
  list,
  fetchProfile,
  publish,
  sendApprovalStatus,
  checkLimits,
};
