import React from "react";
import Joi from "joi-browser";
import Form from "./../common/form";
import profile from "../../services/profileService";
import _ from "lodash";
import { getCurrentUser } from "../../services/authService";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

class AboutMe extends Form {
  state = {
    data: {
      shortDescription: this.props.data
        ? this.props.data["shortDescription"]
        : "",
      description: this.props.data ? this.props.data["description"] : "",
    },
    errors: {},
    limit: 0,
  };

  schema = {
    shortDescription: Joi.string().min(5).max(70).label("Short Description"),
    description: Joi.string().min(5).label("Description"),
  };

  doSubmit = async () => {
    try {
      await profile.updateProfile(
        this.state.data,
        "aboutme",
        this.props.profileUserId
      );
      toast.success("Your profile has been updated! 🥳");
      this.props.handleDataUpdate(this.state.data, "aboutme");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  handleLimit = () => {
    const user = getCurrentUser();
    let userlimit = 300; //Default
    if (user.current_package.limits) {
      const filterlimit = _.filter(user.current_package.limits, function (o) {
        return o.k === "Description";
      });
      if (
        filterlimit &&
        filterlimit[0] &&
        filterlimit[0].v &&
        filterlimit[0].v[0]
      ) {
        userlimit = filterlimit[0].v[0];
      }
    }
    this.setState({ limit: userlimit });
  };

  componentDidMount = () => {
    this.handleLimit();
  };

  render() {
    return (
      <>
        <ToastContainer />
        <div className="col-md-12  profile-detail">
          <div className="">
            <h5 className="mb-0 py-2 color-green">
              <i className="ti-themify-favicon"></i> About me{" "}
            </h5>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 align-self-center">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              {this.renderInput(
                "shortDescription",
                "Short Description",
                "text",
                "col-12"
              )}
              {this.renderTextArea(
                "description",
                "Description",
                this.state.limit,
                5,
                "col-12"
              )}
            </div>
            {this.renderButton("Update Profile", "w-100")}
          </form>
        </div>
      </>
    );
  }
}

export default AboutMe;
