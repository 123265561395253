import React from "react";
import Form from "./form";

class Confirmation extends Form {
  state = {};
  render() {
    return (
      <>
        <div class="modal-body">
          <div>{this.props.details}</div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={this.props.onClose}
          >
            No
          </button>
          <button
            type="button"
            class={`btn btn btn-primary`}
            onClick={() => this.props.onConfirm(this.props.item)}
          >
            Yes
          </button>
        </div>
      </>
    );
  }
}

export default Confirmation;
