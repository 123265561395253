import React, { Component } from "react";

class TopBat extends Component {
  state = {};

  componentDidMount() {
    document.getElementById("body").className = "loaded";
  }

  render() {
    return (
      <div className="topbar">
        <nav className="navbar-custom">
          <ul className="list-inline float-right mb-0">
            <li className="list-inline-item dropdown notification-list p-4">
                         </li>
          </ul>

          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-light waves-effect">
                <i className="mdi mdi-menu" onClick={this.props.openMenu}></i>
              </button>
            </li>
          </ul>
          <div className="clearfix"></div>
        </nav>
      </div>
    );
  }
}

export default TopBat;
