import React, { Component } from "react";
import Joi from "joi-browser";
import _ from "lodash";
import Input from "./input";
import InputIcon from "./inputIcon";
import InputDateIcon from "./inputDateIcon";
import TextArea from "./textArea";
import CheckBox from "./checkBox";
import SingleSelect from "./singleSelect";
import SingleCitySelect from "./singleCitySelect";
import MultipleSelect from "./multipleSelect";
import PhoneDetails from "./phoneDetails";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.erros };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleChangeDt = ({ currentTarget: input }) => {
    const errors = { ...this.state.erros };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else delete errors[input.name];

    const data = { ...this.state.data };
    if (input.value.length < 11) {
      data[input.name] = input.value;

      this.setState({ data, errors });
    }
  };

  handleChangeSelect = (e) => {
    const errors = { ...this.state.erros };
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value.trim();
    this.setState({ data, errors });
  };

  handleChangeBoolean = ({ currentTarget: input }) => {
    const errors = { ...this.state.erros };
    const data = { ...this.state.data };
    data[input.name] = !data[input.name];

    this.setState({ data, errors });
  };

  handleChangeMultSelect = ({ currentTarget: input }, arrayName, value) => {
    const errors = { ...this.state.erros };
    const data = { ...this.state.data };
    const findItem = _.findIndex(data[arrayName], function (o) {
      return o == value;
    });

    if (findItem === -1) {
      data[arrayName].push(value);
    } else {
      _.pull(data[arrayName], value);
    }
    this.setState({ data, errors });
  };

  handleChangeArrayObj = (arrayname, { currentTarget: input }) => {
    const inputval = input.value;
    const errors = { ...this.state.erros };
    const data = { ...this.state.data };

    const arrpos = _.findIndex(data[arrayname], function (o) {
      return o.key === input.name;
    });

    if (arrpos === -1) {
      data[arrayname].push({ key: input.name, value: inputval });
    } else {
      _.set(data[arrayname][arrpos], `value`, inputval);
    }

    //remove empty
    let rest = [];
    for (let i = 0; i < data[arrayname].length; i++) {
      if (data[arrayname][i]["value"] !== "") {
        rest.push(data[arrayname][i]);
      }
    }
    //
    _.set(data, arrayname, rest);
    this.setState({ data, errors });
  };

  renderButton(label, className) {
    return (
      <button
        className={`btn btn-primary ${className}`}
        disabled={this.validate()}
      >
        {label}
      </button>
    );
  }

  renderInput(name, label, type, className) {
    const { data, errors } = this.state;
    return (
      <Input
        label={label}
        name={name}
        onChange={this.handleChange}
        value={data[name]}
        type={type}
        error={errors[name]}
        className={className}
      />
    );
  }

  renderInputIcon(name, label, type, className, icon) {
    const { data, errors } = this.state;
    return (
      <InputIcon
        label={label}
        name={name}
        onChange={this.handleChange}
        value={data[name]}
        type={type}
        error={errors[name]}
        className={className}
        icon={icon}
      />
    );
  }

  renderInputDateIcon(name, label, type, className, icon) {
    const { data, errors } = this.state;
    return (
      <InputDateIcon
        label={label}
        name={name}
        onChange={this.handleChangeDt}
        value={data[name]}
        type={type}
        error={errors[name]}
        className={className}
        icon={icon}
      />
    );
  }

  renderInputIconArrayObj(
    arrayname,
    name,
    label,
    type,
    className,
    icon,
    maxLength
  ) {
    const { data, errors } = this.state;

    const arrpos = _.findIndex(data[arrayname], function (o) {
      return o.key === name;
    });

    let value = "";

    if (arrpos !== -1) {
      value = data[arrayname][arrpos]["value"];
    }

    return (
      <InputIcon
        label={label}
        name={name}
        onChange={(e) => this.handleChangeArrayObj(arrayname, e)}
        value={value}
        type={type}
        error={errors[name]}
        className={className}
        icon={icon}
        maxLength={maxLength}
      />
    );
  }

  renderTextArea(name, label, maxLength, rows, className) {
    const { data, errors } = this.state;
    return (
      <TextArea
        label={label}
        name={name}
        onChange={this.handleChange}
        value={data[name]}
        error={errors[name]}
        className={className}
        maxLength={maxLength}
        rows={rows}
      />
    );
  }

  renderCheckBox(name, label, className) {
    const { data, errors } = this.state;
    return (
      <CheckBox
        label={label}
        name={name}
        onChange={this.handleChangeBoolean}
        value={data[name]}
        className={className}
      />
    );
  }

  renderSingleSelect = (name, label, className, values, optionalOnChange) => {
    const { data, errors } = this.state;
    return (
      <SingleSelect
        label={label}
        name={name}
        onChange={optionalOnChange || this.handleChangeSelect}
        values={values}
        selected={data[name]}
        className={className}
      />
    );
  };

  renderSingleCitySelect = (name, label, className, values) => {
    const { data, errors } = this.state;
    return (
      <SingleCitySelect
        label={label}
        name={name}
        onChange={this.handleChangeSelect}
        values={values}
        selected={data[name]}
        className={className}
      />
    );
  };

  renderMultipleSelect(name, label, className, values) {
    const { data, errors } = this.state;
    return (
      <MultipleSelect
        label={label}
        name={name}
        onChange={this.handleChangeMultSelect}
        values={values}
        selected={data[name]}
        className={className}
      />
    );
  }

  renderPhoneDetails(name, key) {
    const { data, errors } = this.state;

    let varfone = data[name][key]["phone"];

    return (
      <PhoneDetails
        name={name}
        item={key}
        onChange={this.handleChangePhone}
        value={varfone}
      />
    );
  }
}

export default Form;
