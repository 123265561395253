import React from "react";
import Joi from "joi-browser";
import Form from "./../common/form";
import user from "../../services/signUpService";
import _ from "lodash";
import { getCurrentUser } from "../../services/authService";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

class ChangeBasicDetails extends Form {
  state = {
    data: {
      name: getCurrentUser().name,
      email: getCurrentUser().email,
      confirmEmail: getCurrentUser().email,
    },
    errors: {},
    limit: 10,
  };

  schema = {
    name: Joi.string().min(2).max(255).label("Name"),
    email: Joi.string().min(5).max(255).required().email().label("Email"),
    confirmEmail: Joi.string().min(5).max(255).email().label("Confirm Email"),
  };

  doSubmit = async () => {
    try {
      if (this.state.data.email !== this.state.data.confirmEmail) {
        toast.warning("'Confirm Email' doesn't match with Email");
        return;
      }
      let newdata = this.state.data;
      delete newdata.confirmEmail;
      await user.changeBasicDetails(newdata);
      toast.success("Your profile has been updated! 🥳");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  render() {
    return (
      <div class="content-page">
        <div class="content">
          <div class="page-content-wrapper">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <h4 class="page-title">Login Details</h4>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="card m-b-30">
                    <div class="card-body">
                      <form onSubmit={this.handleSubmit}>
                        <div className="row">
                          {this.renderInput(
                            "name",
                            "Nickname",
                            "text",
                            "col-12"
                          )}
                          {this.renderInput("email", "Email", "text", "col-12")}

                          {this.renderInput(
                            "confirmEmail",
                            "Confirm Email",
                            "text",
                            "col-12"
                          )}
                        </div>
                        {this.renderButton("Update", "w-100")}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangeBasicDetails;
