import React from "react";

const PreviewVideo = (props) => {
  return (
    <>
      <video width="100%" height="440" controls>
        <source src={props.file} />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default PreviewVideo;
