import React from "react";
import _ from "lodash";

const MultipleSelect = ({
  label,
  name,
  className,
  values,
  selected,
  onChange,
  ...rest
}) => {
  function cbox(index, value) {
    let checked = _.findIndex(selected, function (o) {
      return o === value;
    });

    return (
      <div class={className}>
        <div class="custom-control custom-checkbox mt-2">
          <input
            id={`name${index}`}
            name={`name${index}`}
            type="checkbox"
            class="custom-control-input"
            data-parsley-multiple="groups"
            data-parsley-mincheck="2"
            checked={checked === -1 ? false : true}
            onClick={(e) => onChange(e, name, value)}
          />
          <label className="custom-control-label" for={`name${index}`}>
            {value}
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className={`form-group col`}>
      {label ? <label htmlFor={name}>{label}</label> : ""}
      <div className="row">
        {values.map((item, index) => {
          return cbox(index, item["v"]);
        })}
      </div>
    </div>
  );
};

export default MultipleSelect;
