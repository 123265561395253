import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { limitResults } from "../../config.json";

const Paginations = (props) => {
  const [pButtons, setPButtons] = useState([]);

  useEffect(() => {
    const pagination_buttons = [];
    for (let i = 0; i < props.totalPages; i++) {
      pagination_buttons.push(
        <li
          class={`paginate_button page-item ${
            i + 1 === props.currentPage + 0 ? "active" : ""
          }`}
        >
          <Link
            aria-controls="datatable"
            data-dt-idx="1"
            tabindex="0"
            class="page-link"
            onClick={() => props.handlePageChange(i + 1)}
          >
            {i + 1}
          </Link>
        </li>
      );
    }
    setPButtons(pagination_buttons);
  }, [props]);

  return (
    <div class="row mt-4">
      <div class="col-sm-12 col-md-5">
        <div
          class="dataTables_info"
          id="datatable_info"
          role="status"
          aria-live="polite"
        >
          Showing {props.currentPage * limitResults - limitResults + 1} to{" "}
          {props.currentPage * limitResults >= props.totalFound
            ? props.totalFound
            : props.currentPage * limitResults}{" "}
          of {props.totalFound} entries
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <div
          class="dataTables_paginate paging_simple_numbers"
          id="datatable_paginate"
        >
          {props.totalPages > 1 && (
            <ul class="pagination float-right">
              <li
                class={`paginate_button page-item previous ${
                  props.currentPage === 1 && "disabled"
                }`}
                id="datatable_previous"
              >
                <Link
                  aria-controls="datatable"
                  data-dt-idx="0"
                  tabindex="0"
                  class="page-link"
                  onClick={() => props.handlePageChange(props.currentPage - 1)}
                >
                  Previous
                </Link>
              </li>
              {pButtons.map((item) => item)}

              <li
                class={`paginate_button page-item next" id="datatable_next ${
                  props.currentPage === props.totalPages && "disabled"
                }`}
              >
                <Link
                  aria-controls="datatable"
                  data-dt-idx="7"
                  tabindex="0"
                  class="page-link"
                  onClick={() => props.handlePageChange(props.currentPage + 1)}
                >
                  Next
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Paginations;
