import React, { Component } from "react";
import { imageFolder } from "../../config.json";
import { getCurrentUser } from "../../services/authService";
import _ from "lodash";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import profileService from "../../services/profileService";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const SortableItem = SortableElement(
  ({ value, user, deleteImage, editImage }) => (
    <div className="col-4">
      <div className="p-2 m-2 border">
        {value["processing"] === true ? (
          <img
            src="assets/images/processing.gif"
            alt="processing"
            height="92"
          />
        ) : (
          <>
            <img
              className="item-container img-fluid move"
              src={`${imageFolder}/${user}/${value["thumbnail"]}?${Date.now()}`}
              alt="7"
            />{" "}
            <span
              onClick={() => editImage(`${imageFolder}/${user}`, value)}
              className="pointer"
            >
              Edit
            </span>{" "}
            |{" "}
            <span
              onClick={() => deleteImage(value["original"])}
              className="pointer"
            >
              Delete
            </span>
          </>
        )}
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({ items, user, deleteImage, editImage }) => {
    return (
      <div class="row">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            user={user}
            deleteImage={deleteImage}
            editImage={editImage}
            className="pointer"
          />
        ))}
      </div>
    );
  }
);

class PhotosImageGallery extends Component {
  state = {
    items: this.props.images,
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    this.updateServer();
  };

  updateServer = async () => {
    if (!_.isEqual(this.props.images, this.state.items)) {
      try {
        await profileService.updateProfile(
          this.state.items,
          "images_gallery",
          this.props.profileUserId
        );
        console.log("saved!");
        this.props.handleDataUpdate(this.state.items, "images_gallery");
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.warning(ex.response.data);
        } else {
          toast.error(ex.toString());
        }
      }
    }
  };

  deleteImage = (image) => {
    this.props.handleDelete(image);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ items: this.props.images });
    }
  }
  componentWillReceiveProps(myProps) {
    this.setState({ items: myProps.images });
  }

  render() {
    let user = getCurrentUser();
    user = user["_id"];

    return (
      <>
        {" "}
        <ToastContainer />
        <SortableList
          items={this.state.items}
          user={this.props.profileUserId}
          deleteImage={this.deleteImage}
          onSortEnd={this.onSortEnd}
          editImage={this.props.editImage}
          axis="xy"
          pressDelay="120"
        />
      </>
    );
  }
}

export default PhotosImageGallery;
