import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import user from "../services/signUpService";
import auth from "../services/authService";

const ActiveAccount = () => {
  let { id } = useParams();

  const [authResp, setAuthResp] = useState("processing");

  useEffect(() => {
    async function checkId(id) {
      try {
        await user.activeaccount(id);
        setAuthResp("true");
        setTimeout(() => {
          window.location.href = "/";
        }, 4000);
      } catch (ex) {
        setAuthResp("false");
      }
    }
    checkId(id);
  }, []);

  if (auth.getCurrentUser()) return <Redirect to="/profile" />;
  return (
    <>
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center mt-0">
              <img src="/assets/images/logo.png" height="45" alt="logo" />
            </h3>
            {authResp === "false" ? (
              <h6 className="text-center text-danger">
                Error: Expired or Invalid Account
              </h6>
            ) : (
              ""
            )}

            {authResp === "true" ? (
              <h6 className="text-center text-danger">
                <img
                  src="/assets/images/check-animation.gif"
                  style={{ width: "100%" }}
                />
              </h6>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveAccount;
