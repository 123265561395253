import React from "react";
import { Link, Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import auth from "../services/authService";
import { connectSocket } from "./common/socketClient";

class Login extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
  };

  schema = {
    email: Joi.string().min(5).max(255).required().email(),
    password: Joi.string().min(5).max(255).required(),
  };

  doSubmit = async () => {
    try {
      await auth.login(this.state.data);
      const user = auth.getCurrentUser();

      if (user.isAdmin === true) {
        return (window.location = "/manage/list");
      }

      if (user && user.current_package && user.current_package.package_id) {
        const { state } = this.props.location;
        return (window.location = state ? state.from.pathname : "/profile");
      }
      window.location = "/profile";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.erros };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  componentDidMount() {
    //document.getElementById("body").className = "login-page sign-in loaded";
  }

  componentWillUnmount() {
    //document.getElementById("body").className = "";
  }

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/profile" />;
    return (
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center mt-0">
              <a href="index.html" className="logo logo-admin">
                <img src="assets/images/logo.png" height="45" alt="logo" />
              </a>
            </h3>

            <h6 className="text-center">Sign In</h6>

            <div className="p-3">
              <form className="form-horizontal" onSubmit={this.handleSubmit}>
                {this.renderInput("email", "Email", "text")}
                {this.renderInput("password", "Password", "password")}
                {this.renderButton("Login", "w-100")}
                <div className="w-100 d-flex mt-2">
                  <Link
                    to="forgot-password"
                    className="btn btn-text-secondary float-right m-r-sm"
                  >
                    <i className="mdi mdi-lock"></i> Forgot your password ?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
