import React, { Component } from "react";
import _ from "lodash";
import UploadVideos from "./uploadVideos";
import VideosGallery from "./videosGallery";
import uploadservice from "../../services/uploadService";
import { getCurrentUser } from "../../services/authService";
import { ToastContainer, toast } from "react-toastify";
import { checkLimits } from "../../services/admin/profileAdminServices";

import "react-toastify/dist/ReactToastify.css";

class Videos extends Component {
  state = {
    videos: this.props.data || [],
    editModal: "",
    limit: 0,
  };

  updateVideos = (data, section) => {
    this.setState({ videos: data });
    this.props.handleDataUpdate(data, section, this.props.profileUserId);
  };

  handleDelete = async (filename) => {
    let listOfVideos = this.state.videos;
    const index = _.findIndex(listOfVideos, function (o) {
      return o.file == filename;
    });

    if (index !== -1) {
      try {
        await uploadservice.deleteVideoFile(filename, this.props.profileUserId);
        listOfVideos.splice(index, 1);
        this.updateVideos(listOfVideos, "videos_gallery");
        toast.success("Image deleted sucessfully! 🥳");
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.warning(ex.response.data);
        } else {
          toast.error(ex.toString());
        }
      }
    }
  };

  async componentDidMount() {
    const user = getCurrentUser();
    let limitsList = [];
    if (user.isAdmin) {
      limitsList = await checkLimits(this.props.profileUserId);
      limitsList = limitsList.data.current_package.limits;
    } else {
      limitsList = user.current_package.limits;
    }

    if (limitsList) {
      const filterlimit = _.filter(limitsList, function (o) {
        return o.k === "Videos";
      });

      if (filterlimit.length > 0) {
        this.setState({ limit: parseInt(filterlimit[0].v[0]) });
      }
    }
  }

  handleClose = () => {
    this.setState({ editModal: "" });
  };

  render() {
    return (
      <>
        {this.state.editModal}
        <div className="col-md-12  profile-detail">
          <div className="">
            <h5 className="mb-0 py-2 color-green">
              {" "}
              <i className="ti-control-play"></i> Videos
            </h5>
          </div>
        </div>
        {this.state.limit === 0 ? (
          <div class="alert alert-info w-100 m-2" role="alert">
            Upgrade your plan to use the video features
          </div>
        ) : (
          ""
        )}
        <>
          <div className="col-md-12 col-lg-12 align-self-center">
            <UploadVideos
              limit={this.state.limit - this.state.videos.length}
              handleDataUpdate={this.updateVideos}
              profileUserId={this.props.profileUserId}
            />
          </div>
          <div className="col-12 m-3 px-4">
            <div className="row">
              <VideosGallery
                videos={this.state.videos}
                handleDelete={this.handleDelete}
                handleDataUpdate={this.updateVideos}
                profileUserId={this.props.profileUserId}
              />
            </div>
          </div>
        </>
      </>
    );
  }
}

export default Videos;
