import socketClient from "socket.io-client";
import { serverUrl } from "../../config.json";

export function connectSocket() {
  /* socketClient(serverUrl);
  socketClient.on("connect", "010101", () => {
    console.log(`I'm connected with the back-end`);
  }); */
}

export default { connectSocket };
