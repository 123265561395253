import React from "react";

const TextArea = ({
  name,
  label,
  maxLength,
  rows,
  error,
  className,
  value,
  ...rest
}) => {
  return (
    <div className={`form-group ${className}`}>
      {label ? <label htmlFor="textarea">{label}</label> : ""}
      <textarea
        {...rest}
        name={name}
        className={`form-control`}
        maxLength={maxLength}
        rows={rows}
        value={value}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default TextArea;
