import http from "./httpServices";
import { apiUrl } from "../config.json";

const apiEndpoint = `${apiUrl}/gareports/`;

export async function fetchGAByUser(user_id, period) {
  console.log("fetchGAByUser", user_id, period);
  return await http.get(`${apiEndpoint}by_user/${user_id}/${period}`);
}

export default { fetchGAByUser };
