import React, { Component } from "react";
import { imageFolder } from "../../config.json";
import { getCurrentUser } from "../../services/authService";
import _ from "lodash";

import Modal from "../common/modal";
import Confirmation from "../common/confirmation";
import PreviewVideo from "./previewVideo";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import profileService from "../../services/profileService";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const SortableItem = SortableElement(
  ({ value, user, deleteImage, previewVideo }) => (
    <div className="p-2 m-2">
      <>
        <img
          className="item-container img-fluid move"
          src={`/assets/images/users/videos-icon.jpg`}
          alt="7"
        />{" "}
        <p className="m-0 w-100">
          <b>{value["label"]}</b>
        </p>
        <p className="pointer m-0">
          <span
            onClick={() =>
              previewVideo(
                `${imageFolder}/${user}/videos/${value["file"]}`,
                value["label"]
              )
            }
          >
            Preview
          </span>{" "}
          | <span onClick={() => deleteImage(value["file"])}>Delete</span>
        </p>
      </>
    </div>
  )
);

const SortableList = SortableContainer(
  ({ items, user, deleteImage, previewVideo }) => {
    return (
      <div class="row">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            user={user}
            deleteImage={deleteImage}
            previewVideo={previewVideo}
            className="pointer"
          />
        ))}
      </div>
    );
  }
);

class VideosGallery extends Component {
  state = {
    items: this.props.videos,
    showModal: "",
  };

  previewVideo = (file, label) => {
    const extension = file.split(".")[file.split(".").length - 1];
    console.log(file, file.split(".")[file.split(".").length - 1]);
    this.setState({
      showModal: (
        <Modal
          component={
            <PreviewVideo title={label} file={file} extension={extension} />
          }
          close={this.closeModal}
          title={label}
        />
      ),
    });
  };

  closeModal = () => {
    this.setState({ showModal: "" });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    this.updateServer();
  };

  updateServer = async () => {
    if (!_.isEqual(this.props.videos, this.state.items)) {
      try {
        await profileService.updateProfile(
          this.state.items,
          "videos_gallery",
          this.props.profileUserId
        );
        console.log("saved!");
        this.props.handleDataUpdate(
          this.state.items,
          "videos_gallery",
          this.props.profileUserId
        );
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.warning(ex.response.data);
        } else {
          toast.error(ex.toString());
        }
      }
    }
  };

  deleteImage = (image) => {
    this.setState({
      showModal: (
        <Modal
          component={
            <Confirmation
              details={<p>Do you confirme deleting this video?</p>}
              onClose={this.closeModal}
              onConfirm={this.deleteConfirmed}
              item={image}
            />
          }
          close={this.closeModal}
          title={"Confirm deleting?"}
        />
      ),
    });
  };

  deleteConfirmed = (image) => {
    this.props.handleDelete(image);
    this.closeModal();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ items: this.props.videos });
    }
  }
  componentWillReceiveProps(myProps) {
    this.setState({ items: myProps.videos });
  }

  render() {
    return (
      <>
        {this.state.showModal}
        <ToastContainer />
        {this.state.items ? (
          <SortableList
            items={this.state.items}
            user={this.props.profileUserId}
            deleteImage={this.deleteImage}
            previewVideo={this.previewVideo}
            onSortEnd={this.onSortEnd}
            axis="xy"
            pressDelay="120"
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default VideosGallery;
