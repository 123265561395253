import http from "./httpServices";
import { apiUrl } from "../config.json";

const apiEndpoint = `${apiUrl}/upload`;

export async function upload(files, update_user_id) {
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  const data = new FormData();
  for (let i = 0; i < files.length; i++) {
    data.append("file", files[i]);
  }
  return await http.post(apiEndpoint + "/photos/" + update_user_id, data, {});
}

export async function uploadAvatar(file, update_user_id) {
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  const data = new FormData();
  data.append("file", file[0]);

  return await http.post(
    apiEndpoint + "/photos/" + update_user_id + "?avatar=1",
    data,
    {}
  );
}

export async function uploadVideos(files, update_user_id) {
  console.log(update_user_id);
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  const data = new FormData();
  for (let i = 0; i < files.length; i++) {
    data.append("file", files[i]);
  }
  return await http.post(apiEndpoint + "/videos/" + update_user_id, data, {});
}

export async function deletefile(file, update_user_id) {
  return await http.delete(apiEndpoint + "/" + file + "/" + update_user_id);
}

export async function deleteVideoFile(file, update_user_id) {
  return await http.delete(
    apiEndpoint + "/video/" + file + "/" + update_user_id
  );
}

export async function deleteAvatar(file, update_user_id) {
  return await http.delete(
    apiEndpoint + "/" + file + "/" + update_user_id + "?avatar=1"
  );
}

export async function editImage(
  crop,
  zoom,
  rotation,
  image,
  avatar,
  update_user_id
) {
  const body = {
    crop,
    zoom,
    rotation,
    image,
    avatar,
  };
  return await http.post(apiEndpoint + "/edit/photo/" + update_user_id, body);
}

export default {
  upload,
  uploadAvatar,
  deleteAvatar,
  deletefile,
  editImage,
  uploadVideos,
  deleteVideoFile,
};
