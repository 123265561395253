import React, { useCallback, useState, useEffect } from "react";
import { imageFolder } from "../../config.json";
import { getCurrentUser } from "../../services/authService";
import { useDropzone } from "react-dropzone";
import profile from "../../services/profileService";
import _ from "lodash";

import Modal from "../common/modal";
import PhotosEditor from "./PhotosEditor";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import uploadservice from "../../services/uploadService";

function ProfileAvatar(props) {
  let user = getCurrentUser();
  user = user["_id"];

  const [avatar, setAvatar] = useState();
  const [modal, setModal] = useState();

  useEffect(() => {
    setAvatar(props.avatar);
  }, [props.avatar]);

  const onDrop = useCallback(async (acceptedFiles) => {
    // Test Limit
    if (acceptedFiles.length > 1) {
      return toast.warning(
        `Your files couldn't be upload. You can only update 1 main image.`
      );
    }
    // Test Type
    const validateFilesType = _.findIndex(acceptedFiles, function (o) {
      return (
        o.type !== "image/jpeg" &&
        o.type !== "image/jpg" &&
        o.type !== "image/png"
      );
    });
    if (validateFilesType !== -1) {
      return toast.warning(
        `Only PNG, JPEG, and JPG files are allowed to upload.`
      );
    }

    // Test file size
    const validateFilesSize = _.findIndex(acceptedFiles, function (o) {
      return o.size > 10000000;
    });
    if (validateFilesSize !== -1) {
      return toast.warning(`The upload limit is 10MB per file.`);
    }

    try {
      const updatedData = await uploadservice.uploadAvatar(
        acceptedFiles,
        props.profileUserId
      );
      toast.success("Your photos has been uploaded sucessfully! 📸");
      console.log("setAvatar", updatedData["data"]);
      setAvatar(updatedData["data"]);

      setModal(
        <Modal
          title="Edit Image"
          close={handleClose}
          component={
            <PhotosEditor
              path={`${imageFolder}/${props.profileUserId}`}
              image={updatedData["data"]}
              processPhoto={handleProcessPhoto}
              close={handleClose}
              avatar={true}
              profileUserId={props.profileUserId}
            />
          }
        />
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data + "_");
      } else {
        toast.error(ex.toString() + "//");
      }
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const deleteAvatar = async (file) => {
    try {
      await uploadservice.deleteAvatar(file, props.profileUserId);
      toast.success("Your photos has been uploaded sucessfully! 📸");
      setAvatar({});
      props.handleDataUpdate({ original: "", thumbnail: "" }, "avatar");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  const handleClose = () => {
    setModal("");
  };

  const handleProcessPhoto = (image, status) => {
    if (status === true) {
      setAvatar({});
    } else {
      setAvatar(image);
    }
  };

  const handleOnChange = async (e) => {
    try {
      const value = e === "true";
      await profile.updateProfile(value, "available");
      toast.success("Your profile has been updated! 🥳");
      props.handleAvailable(value);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.warning(ex.response.data);
      } else {
        toast.error(ex.toString());
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {modal}
      <div className="card m-b-30">
        <div className="card-body">
          {avatar && avatar["thumbnail"] ? (
            <div className="row">
              <div className="col-12 text-center">Featured image</div>
              <div className="col-12 text-center">
                <img
                  src={`${imageFolder}/${props.profileUserId}/${
                    avatar["thumbnail"]
                  }?${Date.now()}`}
                  alt=""
                  className="mx-auto d-block profileimg"
                />
              </div>
              <div className="col-6 text-center">
                <span
                  className="pointer"
                  onClick={(e) => deleteAvatar(avatar["original"])}
                >
                  Remove
                </span>
              </div>
              <div className="col-6 text-center">
                <span
                  className="pointer"
                  onClick={(e) =>
                    setModal(
                      <Modal
                        title="Edit Image"
                        close={handleClose}
                        component={
                          <PhotosEditor
                            path={`${imageFolder}/${props.profileUserId}`}
                            image={avatar}
                            processPhoto={handleProcessPhoto}
                            close={handleClose}
                            avatar={true}
                            profileUserId={props.profileUserId}
                          />
                        }
                      />
                    )
                  }
                >
                  Edit Image
                </span>
              </div>
            </div>
          ) : (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="col-12 text-center">Featured image</div>
              <p className="text-center pointer">
                <img
                  src="/assets/images/users/Upload-Thumbnail.jpg"
                  alt=""
                  className="mx-auto d-block profileimg"
                />
                Upload
              </p>
            </div>
          )}

          {/*
          <div className="text-center mt-3">
            <h5 className="mt-2 mb-0">{props.nickname}</h5>
            <p className="text-muted mb-2 p-2 text-center">
              <select
                value={props.available.toString()}
                className="form-control"
                onChange={(e) => handleOnChange(e.target.value)}
              >
                <option value={"true"}>Available Now</option>
                <option value={"false"}>Not Available</option>
              </select>
            </p>
          </div>
        */}
        </div>
      </div>
    </>
  );
}

export default ProfileAvatar;
