import React from "react";

const CheckBox = ({ name, label, className, value, onChange, ...rest }) => {
  return (
    <div class={className}>
      <div class="custom-control custom-checkbox mt-2">
        <input
          type="checkbox"
          class="custom-control-input"
          id={name}
          name={name}
          data-parsley-multiple="groups"
          data-parsley-mincheck="2"
          checked={value}
          onChange={onChange}
        />
        <label class="custom-control-label" for={name}>
          {label}
        </label>
      </div>
    </div>
  );
};

export default CheckBox;
