import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { Link } from "react-router-dom";
import { register } from "../services/signUpService";
import auth from "../services/authService";

class SignUp extends Form {
  state = {
    data: { name: "", email: "", phone: "", website: "", password: "" },
    errors: {},
  };

  schema = {
    name: Joi.string().min(5).max(255).required().label("Name"),
    phone: Joi.string().required().label("Phone Number"),
    website: Joi.string().min(5).max(255).label("Website"),
    email: Joi.string().min(5).max(255).required().email().label("Email"),
    password: Joi.string().min(5).max(255).required().label("Password"),
  };

  doSubmit = async () => {
    try {
      const response = await register(this.state.data);
      auth.loginWithJwt(response.headers["x-auth-token"]);
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.erros };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  componentDidMount() {
    document.getElementById("body").className = "login-page sign-up loaded";
  }

  componentWillUnmount() {
    document.getElementById("body").className = "";
  }

  render() {
    return (
      <div className="container">
        <div className="login-container">
          <div className="row justify-content-center row align-items-center">
            <div className="col-lg-4 col-md-6">
              <div className="card login-box">
                <div className="card-body">
                  <form onSubmit={this.handleSubmit}>
                    <h5 className="card-title">Sign Up</h5>
                    {this.renderInput("name", "Name", "text")}
                    {this.renderInput("email", "Email", "text")}
                    {this.renderInput("phone", "Phone Number", "text")}
                    {this.renderInput("website", "Website", "text")}
                    {this.renderInput("password", "Password", "password")}
                    {this.renderButton("Sign Up")}
                    <Link
                      to="login"
                      className="btn btn-text-secondary float-right m-r-sm"
                    >
                      Login
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
